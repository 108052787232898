import React from "react"
import { Jumbotron } from "src/ui"

interface Props {
  backgroundImageUrl: string
}

export const JumboFluid: React.FC<Props> = ({ backgroundImageUrl }) => {
  return (
    <section className="section p-0">
      <Jumbotron fluid>
        <div className="cover-img" style={{ backgroundImage: `url(${backgroundImageUrl})` }}></div>
      </Jumbotron>
    </section>
  )
}
