import React from "react"
import { Container, Col, Row } from "src/ui"
import { ImageData } from "ExtendiApp"
import InViewMonitor from "react-inview-monitor"

interface Props {
  blocks: Block[]
}

type Block = {
  text: string
  imageLarge: ImageData
  imageSmall?: ImageData
}

export const PageBlocks: React.FC<Props> = ({ blocks }) => {
  return (
    <section className={"section section-default section-gallery"}>
      <Container fluid className="px-0">
        {blocks.map(({ text, imageSmall, imageLarge }, index) => {
          return index % 2 === 0 ? (
            <InViewMonitor key={index} classNameNotInView="animate-box" classNameInView="animated fadeInUp">
              <Row key={index} className="align-items-center row-gallery">
                <Col lg="6" className="pr-lg-0">
                  <div className="col-caption">
                    {imageLarge.altText && <h4>{imageLarge.altText}</h4>}
                    <p className="mb-0">{text}</p>
                  </div>
                </Col>
                <Col lg="6" className="pl-lg-0">
                  {imageLarge && (
                    <img src={imageLarge.imageUrl} alt={imageLarge.imageUrl} className="img-fluid w-100 shadow-lg" />
                  )}
                </Col>
              </Row>
            </InViewMonitor>
          ) : (
            <InViewMonitor key={index} classNameNotInView="animate-box" classNameInView="animated fadeInUp">
              <Row key={index} className="align-items-center row-gallery">
                <Col lg="6" className="pr-lg-0">
                  {imageLarge && (
                    <img src={imageLarge.imageUrl} alt={imageLarge.imageUrl} className="img-fluid w-100 shadow-lg" />
                  )}
                </Col>
                <Col lg="6" className="pl-lg-0">
                  <div className="col-caption bg-none">
                    {imageLarge.altText && <h4>{imageLarge.altText}</h4>}
                    <p className="mb-0">{text}</p>
                  </div>
                </Col>
              </Row>
            </InViewMonitor>
          )
        })}
      </Container>
    </section>
  )
}
