import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { PageIntro } from "src/components/PageIntro"
import { JumboFluid } from "src/components/JumboFluid"
import { OffersSlider } from "src/components/OffersSlider"
import { Header } from "src/components/Header"
import { PageBlocks } from "../components/PageBlocks"
import { getFluidImageSrc, getFixedImageSrc } from "src/utils/images"
import SEO from "src/components/SEO"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { InPageMenu } from "src/components/InPageMenu"
import InViewMonitor from "react-inview-monitor"

const SubPage: React.FC<any> = ({ data: { mdx }, pageContext }) => {
  if (!mdx.frontmatter || !mdx.frontmatter.title || !mdx.frontmatter.intro || !mdx.frontmatter.intro.title) {
    return <div>No content has been created for this page</div>
  }

  const {
    title: pageTitle,
    intro: { title, lead, content },
    headerImage,
    videoFileName,
    videoFileNameMobile,
    jumboFluidImage,
    pageBlocks,
  } = mdx.frontmatter

  const parentId = pageContext && pageContext.parentId
  const pageId = pageContext && pageContext.pageId

  return (
    <Fragment>
      <SEO title={pageTitle} description={content} />

      {/* HEADER */}
      <Header
        backgroundImageUrl={getFluidImageSrc(headerImage) || ""}
        videoName={videoFileName}
        videoNameMobile={videoFileNameMobile}
      />

      {/* INTRO */}
      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
        <PageIntro title={title} lead={lead}>
          {content && content.length ? content : <MDXRenderer>{mdx.body}</MDXRenderer>}
        </PageIntro>
      </InViewMonitor>

      {/* PARALLAX */}
      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
        {jumboFluidImage && <JumboFluid backgroundImageUrl={getFixedImageSrc(jumboFluidImage) || ""} />}
      </InViewMonitor>

      {/* ALTERNATED PAGE BLOCK */}
      {pageBlocks && pageBlocks.length ? (
        <PageBlocks
          blocks={pageBlocks.map(block => ({
            text: block.text,
            imageLarge: {
              imageUrl: getFixedImageSrc(block.imageLarge.image),
              altText: block.imageLarge.altText,
            },
            imageSmall:
              block.imageSmall && block.imageSmall.image
                ? {
                    imageUrl: getFixedImageSrc(block.imageSmall.image),
                    altText: block.imageSmall.altText,
                  }
                : undefined,
          }))}
        />
      ) : null}

      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
        <InPageMenu currentPageParentId={parentId} currentPageId={pageId} />
      </InViewMonitor>

      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
        <OffersSlider />
      </InViewMonitor>
    </Fragment>
  )
}

export default SubPage

export const query = graphql`
  query SubPage($locale: String!, $title: String!) {
    mdx(frontmatter: { title: { eq: $title } }, fields: { locale: { eq: $locale } }) {
      frontmatter {
        title
        intro {
          title
          lead
          content
        }
        headerImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1900) {
              ...GatsbyImageSharpFluid
              srcWebp
            }
          }
        }
        videoFileName
        videoFileNameMobile
        jumboFluidImage {
          publicURL
          childImageSharp {
            fixed(width: 1600, height: 600, quality: 85) {
              ...GatsbyImageSharpFixed
              srcWebp
            }
          }
        }
        pageBlocks {
          text
          imageLarge {
            image {
              publicURL
              childImageSharp {
                fixed(width: 1000, height: 600, quality: 90) {
                  ...GatsbyImageSharpFixed
                  srcWebp
                }
              }
            }
            altText
          }
          imageSmall {
            image {
              publicURL
              childImageSharp {
                fixed(width: 400, height: 500, quality: 90) {
                  ...GatsbyImageSharpFixed
                  srcWebp
                }
              }
            }
            altText
          }
        }
      }
      body
    }
  }
`
